import * as React from 'react'

import { HorizontalAlignment, i18n } from '@thg-commerce/enterprise-core'
import {
  Carousel,
  CarouselButtonPlacement,
} from '@thg-commerce/gravity-patterns'

import { LogoImageCollectionThemeInterface } from '../theme'

import { LogoPicture, LogoProps } from './LogoImage'
import {
  Container,
  StyledGrid,
  StyledGridItem,
  SubTitle,
  Title,
} from './styles'

export interface LogoImageCarouselProps {
  title?: string
  subtitle?: string
  logos: LogoProps[]
  horizontalAlignment?: HorizontalAlignment
  itemTextHorizonalAlignment?: HorizontalAlignment
  theme: LogoImageCollectionThemeInterface
}

export const LogoImageCarousel = ({
  horizontalAlignment = 'center',
  ...props
}: LogoImageCarouselProps) => {
  const logoImages = props.logos.map((logo: LogoProps, index: number) => (
    <LogoPicture
      {...logo}
      horizontalAlignment={
        props.itemTextHorizonalAlignment || horizontalAlignment
      }
      key={index}
    />
  ))

  const carouselText = {
    leftScrollLabel: i18n('carousel.controls.left.arialabel'),
    rightScrollLabel: i18n('carousel.controls.right.arialabel'),
  }

  const colTitleStart = [
    { left: 1, center: props.theme.containerGrid.centerColstart[0], right: 5 },
    { left: 1, center: props.theme.containerGrid.centerColstart[1], right: 7 },
  ]

  return (
    <Container horizontalAlignment={horizontalAlignment}>
      <StyledGrid columns={12}>
        <StyledGridItem
          colSpan={props.theme.containerGrid.colspan}
          colStart={
            horizontalAlignment
              ? [
                  1,
                  colTitleStart[0][horizontalAlignment],
                  colTitleStart[1][horizontalAlignment],
                  colTitleStart[1][horizontalAlignment],
                ]
              : 1
          }
        >
          {props.title && <Title>{props.title}</Title>}
          {props.subtitle && <SubTitle>{props.subtitle}</SubTitle>}
        </StyledGridItem>
      </StyledGrid>

      <Carousel
        items={logoImages}
        i18n={carouselText}
        itemsPerSlide={[2, 4, 6, 6]}
        hideControlsOnSingleSlide={true}
        removeControlsOnSingleSlide={true}
        inactiveSlideFullOpacity={true}
        buttonPlacement={
          horizontalAlignment === 'right'
            ? CarouselButtonPlacement.BottomReversed
            : CarouselButtonPlacement.BottomRight
        }
        indicatorStyle="letterbox"
        swipeable={true}
      />
    </Container>
  )
}
